import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_EMPLOY}`,
  headers: {
    platform: "agent",
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403 || error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
