import "../Styles/Dashboard.css";
import { AiOutlineSearch } from "react-icons/ai";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import chicken from "../Images/chicken.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BeatLoader } from "react-spinners";
import { getProducts, search, products, product, awafiBalance } from "../api";
import {
  dashboardImgm,
  allBranches,
  tables,
  dashboardImg,
  categories,
  getTablePrice,
} from "../api";
import safari from "../Images/sfari.jpg";
import { LinearProgress, Box } from "@mui/material";
import { Dropdown, DropdownButton } from "react-bootstrap";
import EnhancedDropdownButton from "../Components/DropDownButton";

import local from "../Images/delievery.jpeg";
import romancLogo from "../Images/roman-logo.png";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import orangeCircle from "../Images/orange-circle.svg";
import whiteCircle from "../Images/white-circle.svg";
import tabelsAvilable from "../Images/tabelsAvilable.svg";
import tableUnavilable from "../Images/tableUnavilable.svg";
import { makeStyles } from "@mui/system";
import CacheDataComponent from "../Components/CacheDataComponent";

const CustomFormControlLabel = styled(FormControlLabel)`
  && .MuiTypography-root {
    font-family: "cairo";
    text-align: right;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#E47100",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#E47100",
          borderColor: "#E47100",
          "&:hover": {
            borderColor: "#E47100",
            backgroundColor: "rgba(228, 113, 0, 0.04)", // Optional hover effect
          },
        },
      },
    },
  },
});

function Dashboard(props) {
  const { t, i18n } = useTranslation();
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [tbls, setTbls] = useState([]);
  const [phoneNum, setPhoneNum] = useState();
  const [img, setImg] = useState("");
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedTableId, setSelectedTableId] = useState(null);

  const [clientType, setClientType] = useState("normal");
  const [clientTypePrice, setClientTypePrice] = useState(null);
  const [progress, setProgress] = useState(0);
  const [hideProgress, setHideProgress] = useState(null);

  const [server, setServer] = useState("");

  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [errorMsgModel2, setErrorMsgModel2] = useState(false);
  const [err, setErr] = useState();
  const [loadingCache, setLoadingCache] = useState(true);

  const [selectedTableType, setSelectedTableType] = useState();

  const types = JSON.parse(localStorage.getItem("types"));
  const branchId = parseInt(localStorage.getItem("branchId"));
  const token = localStorage.getItem("token");

  const [loadTable, setLoadTable] = useState();

  const [tablePrices, setTablePrices] = useState();
  const [selectedPrice, setSelectedPrice] = useState();

  const handleClose = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    const isMahly = process.env.REACT_APP_MAHLY === "true"; // Check if REACT_APP_MAHLY is true
    const isTabletypeExist = localStorage.getItem("tableType") !== null; // Check if tableType exists in localStorage

    console.log("isMahly:", isMahly);
    console.log(
      "isTabletypeExist (true if tableType exists):",
      isTabletypeExist
    );

    console.log(`last check ${isMahly && isTabletypeExist}`);

    // Logic: show only if isMahly is true AND tableType exists
    if (isMahly && isTabletypeExist) {
      setServer("show");
      console.log(
        "Both REACT_APP_MAHLY is true and tableType exists, server set to 'show'"
      );
    } else {
      setServer("notshow");
      console.log(
        "Either REACT_APP_MAHLY is false or tableType does not exist, server set to 'notshow'"
      );
    }
  }, []);

  const handleTableClick = (selectedTable, selectedTableId, type, isVip) => {
    setSelectedTable(selectedTable);
    setSelectedTableId(selectedTableId);
    setSelectedTableType(type);

    localStorage.setItem("selectedTable", selectedTable);
    localStorage.setItem("selectedTableId", selectedTableId);
  };

  const handleChangeClientType = (event) => {
    const clientType = event.target.value;
    setClientType(clientType); // This is asynchronous

    // Filter based on the event's value, not the potentially outdated state
    setTbls((res) => {
      return res.filter((tbl) => {
        if (clientType === "vip") {
          return tbl.isVIP === 1;
        } else {
          return tbl.isVIP === 0;
        }
      });
    });

    console.log(clientTypePrice);

    if (clientType === "normal") {
      localStorage.removeItem("selectedPriceTable");
    } else {
      localStorage.setItem("selectedPriceTable", selectedPrice);
    }

    console.log("selectedPriceTable");

    localStorage.setItem("clientTypePrice", clientTypePrice);
  };

  let history = useNavigate();

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  const Schema = yup.object().shape({
    phone: yup
      .string()
      .required("validation.phone_required")
      .matches(phoneRegEx, "validation.phone_not_valid"),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Schema,
    //onSubmit,
  });

  useEffect(() => {
    localStorage.removeItem("selectedPriceTable");
    localStorage.removeItem("selectedTable");
    localStorage.removeItem("selectedTableId");
    getTablePrice(localStorage.getItem("token")).then((res) => {
      setTablePrices(res.data.data);
      setSelectedPrice(res.data.data[0]);
    });
    localStorage.setItem("isVip", 0);
  }, []);

  const phoneRef = useRef();
  useEffect(() => {
    setLoadTable(true);
    localStorage.removeItem("PaymentMethodDisc");
    localStorage.removeItem("selectPayment");
    const token = localStorage.getItem("token");
    const branchId = parseInt(localStorage.getItem("branchId"));

    tables(token, branchId, "free")
      .then((response) => {
        const newClientTypePrice =
          clientType === "normal"
            ? response.data.data.tablePrices.normal_families_table_price ||
              response.data.data.tablePrices.normal_individuals_table_price
            : response.data.data.tablePrices.vip_families_table_price ||
              response.data.data.tablePrices.vip_individuals_table_price;
        if (clientType === "vip") {
          setTbls(response.data.data.tables.filter((res) => res.isVIP === 1));
          setClientTypePrice(newClientTypePrice);
          localStorage.setItem("clientTypePrice", newClientTypePrice);
          localStorage.setItem("isVip", 1);

          setLoadTable(false);
        } else {
          setTbls(response.data.data.tables.filter((res) => res.isVIP === 0));
          setClientTypePrice(newClientTypePrice);
          localStorage.setItem("clientTypePrice", newClientTypePrice);
          localStorage.setItem("isVip", 0);
          setLoadTable(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoadTable(false);
      });

    console.log(clientType);
  }, [clientType]);

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key

    if (e.key == "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    localStorage.removeItem("userAwaifiBalance");

    const token = localStorage.getItem("token");
    dashboardImg(token).then((response) => {
      setImg(response.data.data.appIntro);

      setImgLoading(false);
    });
  }, []);

  //takes phone number
  //if phone exists customer data is stored and user is redirected to add an order
  //if not found user is redirected to add a new customer
  function handleSearch() {
    if (phoneNum == null) {
      setErr("validation.phone_required");
      setErrorMsgModel2(true);
    } else if (phoneNum !== null && !errorMsgModel2) {
      setLoading(true);
      const token = localStorage.getItem("token");

      let data = {
        phone: phoneRef.current.value,
      };

      search(token, data)
        .then((response) => {
          if (response.data.message === "success") {
            localStorage.removeItem("PaymentMethodDisc");
            localStorage.removeItem("PaymentMethodDiscContd");
            localStorage.setItem("discount", "false");
            localStorage.removeItem("promoCode");
            localStorage.removeItem("editNotes");
            localStorage.removeItem("cancelOrderId");
            localStorage.removeItem("tableDiscount");
            localStorage.removeItem("tableDiscountType");
            localStorage.removeItem("tableAccount");
            localStorage.removeItem("notes");
            localStorage.removeItem("notesCheckbox");
            localStorage.removeItem("orderDate");
            localStorage.removeItem("orderTime");
            localStorage.removeItem("tableNum");
            localStorage.removeItem("displayId");
            localStorage.removeItem("orderType");
            localStorage.removeItem("now");
            localStorage.removeItem("orderSource");
            localStorage.removeItem("offer");
            localStorage.removeItem("branchOrderTime");
            localStorage.removeItem("userAwaifiBalance");

            localStorage.removeItem("branchId");
            localStorage.removeItem("orderDistrictId");
            localStorage.removeItem("addressId");
            localStorage.removeItem("externalOrderId");

            //  this.clearBasket();
            if (response.data.data.length > 0) {
              console.log(response.data.data);
              awafiBalance(token, phoneNum)
                .then((res) => {
                  localStorage.setItem(
                    "userAwaifiBalance",
                    res.data.data.expirableWalletBalance
                  );
                })
                .catch((err) => {
                  console.log(err);
                });

              localStorage.setItem("customerId", response.data.data[0].id);
              localStorage.setItem("customerName", response.data.data[0].name);
              localStorage.setItem(
                "customerNotes",
                response.data.data[0].notes
              );
              localStorage.setItem(
                "customerPhone",
                response.data.data[0].phone
              );
              localStorage.setItem(
                "customerPhoto",
                response.data.data[0].photo
              );
              localStorage.setItem(
                "customerAwafi",
                response.data.data[0].joinLoyalty
              );
              localStorage.setItem(
                "accountType",
                response.data.data[0].profileType == "Company User"
                  ? true
                  : false
              );
              localStorage.setItem(
                "organizationId",
                response.data.data[0].userOrganizationId
              );
              history("/add-order");
              /*this.setState({
        customerFoundRedirect:true,
        customerPhone: response.data.data[0].phone,

      }); */
            } else {
              localStorage.setItem("customerId", "");
              localStorage.setItem("customerName", "");
              localStorage.setItem("customerNotes", "");
              localStorage.setItem("customerPhone", JSON.stringify(data));
              localStorage.setItem("customerPhoto", "");
              localStorage.setItem("customerAwafi", "");
              history("/add-customer");
              /*this.setState({
        customerNotFoundRedirect:true
      }); */
            }
          } else {
            setErrorMsgModel(true);
            setMsgAr("dashboard.notVerified");
            setLoading(false);
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            setErrorMsgModel(true);
            setMsgAr("dashboard.wrongNumber");
            setLoading(false);
          } else if (error.response.status === 403) {
            props.setErr(true);
            props.setMsg(t("validation.noPermissionAction"));
            props.setStyle({ top: "50%" });

            setTimeout(function () {
              props.setErr(false);
              setLoading(false);
            }, 2500);
          } else if (error.response.status === 401) {
            history("/");
          } else {
            setLoading(false);
          }
        });
    }
  }

  const handlePriceSelect = (price) => {
    setSelectedPrice(price);
    localStorage.setItem("selectedPriceTable", price);
  };

  function handlePhone(e) {
    let newStr = e.target.value;
    let arr = [];
    for (let i = 0; i < newStr.length; i++) {
      if (!isNaN(newStr[i]) && newStr[i] !== " ") {
        arr.push(newStr[i]);
      }
    }

    setPhoneNum(arr.join(""));
    console.log(arr.join(""));

    console.log(arr.join("") == "");

    if (arr.join("") == "") {
      setErr("validation.phone_required");
      setErrorMsgModel2(true);
    } else {
      setErrorMsgModel2(false);
    }

    if (!arr.join("").match(phoneRegEx) && !arr.join("").toString() == "") {
      setErr("validation.phone_not_valid");
      setErrorMsgModel2(true);
    } else if (
      arr.join("").match(phoneRegEx) &&
      arr.join("").toString() == ""
    ) {
      setErrorMsgModel2(false);
    }
  }

  const handleGotoType = (typeId) => {
    localStorage.setItem("isVip", 0);
    localStorage.setItem("orderType", typeId);
    if (typeId == 5 && server === "notshow") {
      // setOpenMenu(true);
      return;
    } else if (server === "show" && typeId == 5) {
      setOpenMenu(true);
    } else {
      history("/menu");
    }
  };

  return (
    <div>
      <CacheDataComponent
        setProgress={setProgress}
        setLoadingCache={setLoadingCache}
      />

      {imgLoading ? (
        <div style={{ height: "80vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={imgLoading} size={30} />
        </div>
      ) : localStorage.getItem("type") == "cashier" ? (
        <div className="cashir container">
          <Dialog
            dir={i18n.language == "ar" ? "rtl" : "ltr"}
            open={openMenu}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                fontFamily: "cairo",
                borderRadius: "10px",
              },
            }}
          >
            {loadTable == true ? (
              <div style={{ height: "20vh", width: "60vw" }} className="loader">
                <BeatLoader color={"#E47100"} loading={loadTable} size={30} />
              </div>
            ) : (
              <>
                <DialogContent
                  style={{ fontFamily: "cairo", overflow: "hidden" }}
                  align={i18n.language == "ar" ? "rtl" : "ltr"}
                >
                  <FormControl component="div">
                    <Typography style={{ fontFamily: "cairo" }} variant="body1">
                      {t("pickOrderType")}
                    </Typography>
                    <RadioGroup
                      dir={i18n.language == "ar" ? "rtl" : "ltr"}
                      align={i18n.language == "ar" ? "rtl" : "ltr"}
                      row
                      name="client_type"
                      value={clientType}
                      onChange={handleChangeClientType}
                    >
                      <CustomFormControlLabel
                        style={{
                          fontFamily: "cairo",
                          position:
                            i18n.language === "ar" ? "relative" : "static",
                          right: i18n.language === "ar" ? "-2vw" : "auto",
                        }}
                        value="normal"
                        control={<Radio color="primary" />}
                        label={t("normal")}
                        id="normal"
                        className="customLabel"
                      />
                      <CustomFormControlLabel
                        style={{ fontFamily: "cairo !important" }}
                        value="vip"
                        control={<Radio color="primary" />}
                        label={t("vip")}
                        id="vip"
                      />
                    </RadioGroup>
                  </FormControl>
                </DialogContent>

                {/* {clientTypePrice != null && (
                  <DialogContent
                    direction="row"
                    align={i18n.language == "ar" ? "rtl" : "ltr"}
                  >
                    <Grid
                      dir={i18n.language == "ar" ? "rtl" : "ltr"}
                      container
                      direction="row"
                      align={i18n.language == "ar" ? "rtl" : "ltr"}
                    >
                      <Typography style={{ fontFamily: "cairo" }}>
                        {t("menu.price")} {clientTypePrice} {"  "}{" "}
                        {i18n.language == "ar" ? "ريال" : "SAR"} {"  "}{" "}
                      </Typography>
                    </Grid>
                  </DialogContent>
                )} */}

                {clientType == "vip" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      maxWidth: "5vw !important",
                    }}
                  >
                    <DialogTitle
                      align={i18n.language == "ar" ? "rtl" : "ltr"}
                      style={{ fontFamily: "cairo" }}
                      id="alert-dialog-title"
                    >
                      {t("pickPrice")} :
                    </DialogTitle>
                    <EnhancedDropdownButton
                      selectedPrice={selectedPrice}
                      tablePrices={tablePrices}
                      handlePriceSelect={handlePriceSelect}
                    />
                  </div>
                )}

                <DialogTitle
                  align={i18n.language == "ar" ? "rtl" : "ltr"}
                  style={{ fontFamily: "cairo" }}
                  id="alert-dialog-title"
                >
                  {t("pickTableSeat")}
                </DialogTitle>
                <DialogContent
                  style={{ fontFamily: "cairo", height: "80vh" }}
                  align="center"
                >
                  <div className="tables">
                    {loadTable ? (
                      <div
                        style={{ height: "20vh", width: "60vw" }}
                        className="loader"
                      >
                        <BeatLoader
                          color={"#E47100"}
                          loading={false}
                          size={30}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                          width: "60vw",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                        className="row gy-4"
                      >
                        {tbls && tbls.length > 0 ? (
                          tbls.map((data, index) => (
                            <div className="col-2" key={data.id}>
                              <div
                                onClick={() =>
                                  handleTableClick(
                                    data.name,
                                    data.id,
                                    data.type,
                                    data.isVIP
                                  )
                                }
                                className="avail"
                                style={
                                  data.name != selectedTable
                                    ? {
                                        backgroundImage: `url(${tableUnavilable})`,
                                        height: "10vh",
                                      }
                                    : {
                                        backgroundImage: `url(${tabelsAvilable})`,
                                        color: "white",
                                        height: "10vh",
                                      }
                                }
                              >
                                <p style={{ marginTop: "1vh" }}>{data.name}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="col-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "95%",
                            }}
                          >
                            <button
                              onClick={() => {
                                localStorage.setItem("isWaiting", 1);
                                history("/menu");
                              }}
                              style={{ width: "13vw", marginTop: "4vh" }}
                              className="btn2"
                            >
                              {t("addOrder.waitingList")}{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </DialogContent>

                <ThemeProvider theme={theme}>
                  <DialogActions style={{ justifyContent: "center" }}>
                    {tbls.length > 0 && (
                      <button
                        style={{ margin: "10px" }}
                        className="buttonDiolog"
                        onClick={() => {
                          if (selectedTable == null) {
                            return;
                          }
                          history("/menu");
                        }}
                        autoFocus
                      >
                        {t("addOrder.goto")}
                      </button>
                    )}
                    <button
                      className="buttonDiolog"
                      onClick={handleClose}
                      style={{ margin: "2px" }}
                    >
                      {t("menu.cancel")}
                    </button>
                  </DialogActions>
                </ThemeProvider>
              </>
            )}
          </Dialog>
          <img style={{ marginTop: "2vh", width: "50%" }} src={romancLogo} />
          {loadingCache ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontFamily: "cairo",
                  fontWeight: "800",
                  fontSize: "18px",
                  marginTop: "2vh",
                }}
              >
                {t("pickOrderType")}
              </p>

              <Link to="/my-orders">
                <button className="btn2">{t("dashboard.orders")}</button>
              </Link>
            </div>
          )}

          <div className="container-cashir container">
            {loadingCache ? (
              <div style={{ width: "80%" }}>
                {loadingCache && (
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body1" align="center">
                      <p>جاري تهيئة البيانات ...</p>
                    </Typography>
                    <LinearProgress
                      style={{ height: "10px", borderRadius: "15px" }}
                      variant="determinate"
                      value={progress}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      {Math.round(progress)}%
                    </Typography>
                  </Box>
                )}
              </div>
            ) : (
              types.map((e) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundImage: `url(${e.id !== 1 ? safari : local})`,
                      }}
                      className="card"
                      onClick={() => handleGotoType(e.id)}
                    >
                      <div
                        style={{
                          padding: "10px",
                          backgroundColor:
                            e.id === 5 && server == "notshow" ? "gray" : "",
                        }}
                        className="title"
                      >
                         
                        <p>
                          {e.id !== 1 ? t("mahly") : t("safari")}{"  "}
                          (
                          {localStorage.getItem("tableType") == "families"
                            ? t("familes")
                            : t("individul" )}{" "}
                          )
                        </p>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      ) : (
        <div style={{ overflowX: "hidden" }}>
          <div className="clientSearch">
            <div className="container-fluid">
              <div className="row">
                <div
                  style={
                    i18n.language == "ar"
                      ? { paddingRight: "2em" }
                      : { paddingLeft: "2em" }
                  }
                  className="col-lg-5 srchPhone "
                >
                  <div className="alignVert">
                    <p className="title1"> {t("dashboard.ask")}</p>
                    <p className="title2">{t("dashboard.srchBy")}</p>
                    <div className="searchCustomer">
                      {loading ? (
                        <div style={{ height: "10vh" }} className="loader">
                          <BeatLoader
                            color={"#E47100"}
                            loading={loading}
                            size={30}
                          />
                        </div>
                      ) : (
                        <>
                          <label>{t("dashboard.enterPhone")}</label>

                          <div
                            className="srchField"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              width: "70%",
                            }}
                          >
                            <input
                              type="string"
                              placeholder={t("dashboard.search")}
                              value={phoneNum}
                              id="phone"
                              name="phone"
                              ref={phoneRef}
                              required={true}
                              onInput={(e) => {
                                handlePhone(e);
                              }}
                              onKeyPress={handleKeypress}
                              className={
                                errorMsgModel || errorMsgModel2
                                  ? "invalid"
                                  : null
                              }
                            />

                            <AiOutlineSearch
                              size={30}
                              className="cstmrSrch"
                              style={
                                i18n.language == "ar"
                                  ? { right: "auto", left: "5px" }
                                  : null
                              }
                            />
                          </div>
                          <button
                            onClick={handleSearch}
                            style={
                              i18n.language == "ar"
                                ? null
                                : { marginRight: "auto", marginLeft: "1em" }
                            }
                          >
                            {t("dashboard.search")}
                          </button>
                          <span className="validation">
                            {errorMsgModel2 ? t(err) : null}
                          </span>

                          <span className="validation">
                            {errorMsgModel ? t(msgAr) : null}
                          </span>
                        </>
                      )}
                    </div>
                    {localStorage.getItem("type") !== "cashier" ? (
                      <>
                        <div
                          style={{ marginTop: "1.5em", textAlign: "initial" }}
                        >
                          <p
                            className="or"
                            style={
                              i18n.language == "ar"
                                ? { marginLeft: "1em" }
                                : { marginRight: "1em" }
                            }
                          >
                            {t("dashboard.or")}
                          </p>
                          <p className="orHr"></p>
                        </div>
                        <div className="row" style={{ marginTop: "1.5em" }}>
                          <div className="col">
                            <Link to="/my-orders">
                              <button className="btn2">
                                {t("dashboard.orders")}
                              </button>
                            </Link>
                          </div>

                          <div className="col-6">
                            <Link to="/areas">
                              <button className="btn1">
                                {t("dashboard.area")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{ marginTop: "1.5em", textAlign: "initial" }}
                        >
                          <p
                            className="or"
                            style={
                              i18n.language == "ar"
                                ? { marginLeft: "1em" }
                                : { marginRight: "1em" }
                            }
                          >
                            {t("dashboard.or")}
                          </p>
                          <p className="orHr"></p>
                        </div>
                        <div className="row" style={{ marginTop: "1.5em" }}>
                          <div className="col-6">
                            <Link to="/add-reservation">
                              <button className="btn1">
                                {t("dashboard.tables")}
                              </button>
                            </Link>
                          </div>

                          {/*
<div className='col'>
<Link to="/my-orders">
<button className='btn2'>{t("dashboard.orders")}</button></Link>
</div>
*/}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="col-lg-7"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  {i18n.language == "ar" ? (
                    <div
                      className="chicken"
                      style={{ backgroundImage: `url(${img})` }}
                    ></div>
                  ) : (
                    <div className="chickenSharp">
                      <div
                        className="chickenEn"
                        style={{ backgroundImage: `url(${img})` }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
